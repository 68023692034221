import { useRef } from "react"
import { Dropdown, DropdownItem } from "./Debate-Styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faBookmark, faArrowUpFromBracket, faFlag } from "@fortawesome/free-solid-svg-icons"
import { useOutsideClickDetector } from "../../hooks/useOutsideClickDetector"

const OptionsPanel = props => {
    const { display, setDisplay, isOwner } = props
    const ref = useRef(null)

    // timeout needed because this runs too quickly and the user clicks on the menu to open again
    useOutsideClickDetector(ref, () => setTimeout(() => setDisplay(false), 100))

    if (!display) return null

    return (
        <Dropdown ref={ref}>
            <DropdownItem>
                <FontAwesomeIcon icon={faArrowUpFromBracket} />
                Share
            </DropdownItem>
            <DropdownItem>
                <FontAwesomeIcon icon={faFlag} />
                Report
            </DropdownItem>
            {isOwner && (
                <>
                    <DropdownItem>
                        <FontAwesomeIcon icon={faBookmark} />
                        Save
                    </DropdownItem>
                    <DropdownItem>
                        <FontAwesomeIcon icon={faTrash} />
                        Delete
                    </DropdownItem>
                </>
            )}
        </Dropdown>
    )
}

export default OptionsPanel
