import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Debate from "./Debate"
import { useUserContext } from "../../hooks/useUserContext"
import { useGetRequest } from "../../api/useGetRequest"
import { useDeleteRequest } from "../../api/useDeleteRequest"
import { usePatchRequest } from "../../api/usePatchRequest"
import { useURLContext } from "../../hooks/useURLContext"
import Loader from "../Loader/Loader"

const Debates = ({ hasUploaded }) => {
    const [debates, setDebates] = useState([])
    const [firstDebateId, setFirstDebateId] = useState(null)

    const { user } = useUserContext()
    const { url } = useURLContext()
    const getRequest = useGetRequest()
    const deleteRequest = useDeleteRequest()
    const patchRequest = usePatchRequest()

    // set the first debate
    // useEffect(() => {
    //     const URLstring = params["*"].split("/")
    //     if (URLstring.length < 2) return
    //     if (URLstring[0] !== "debates") return
    //     setFirstDebateId(URLstring[1])
    // }, [params])

    // build the view
    useEffect(() => {
        ;(async () => {
            await getRequest.debates({
                path: `${url}/debates/${firstDebateId}`,
                actions: {
                    setDebates,
                },
            })
        })()
    }, [hasUploaded, firstDebateId]) // TODO: this is incorrect, when refactoring the routes, change this

    const deleteDebate = async debateId => {
        await deleteRequest.debates({
            path: `${url}/debates/${debateId}`,
            actions: {
                setDebates,
            },
            payload: { userId: user._id },
        })
    }

    // displaying interest (click on the 'you agree button')
    const increaseUserInterest = async debateId => {
        await patchRequest.debates({
            path: `${url}/debates/userInterest`,
            payload: {
                userId: user._id,
                debateId,
            },
            actions: {
                setDebates,
            },
        })
    }

    const registerVote = async (debateId, answer) => {
        await patchRequest.debates({
            path: `${url}/debates/vote`,
            payload: {
                userId: user._id,
                debateId,
                answer,
            },
            actions: {
                setDebates,
            },
        })
    }

    const debateList = debates.map(
        debate =>
            debate.creator && (
                <Debate
                    // react identifiers
                    key={debate._id}
                    // debate model properties
                    debateId={debate._id}
                    creator={debate.creator}
                    createdAt={debate.createdAt}
                    debateQuestion={debate.debateQuestion}
                    mainArgos={debate.mainArgos}
                    userInterest={debate.userInterest}
                    yesVote={debate.yesVote}
                    noVote={debate.noVote}
                    image={debate.image}
                    youtubeURL={debate.youtubeURL}
                    // api functions
                    registerVote={registerVote}
                    deleteDebate={deleteDebate}
                    increaseUserInterest={increaseUserInterest}
                />
            )
    )

    return !getRequest.isLoading ? debateList : <Loader />
}

export default Debates
