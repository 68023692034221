import React, { useState, useEffect } from "react"
import TextareaAutosize from "react-textarea-autosize"
import "./Argform-styles.css"

const Argform = props => {
    const {
        /* IDs */
        argoId, // the ID of the argo that generates this form
        parentArgoId, // the parent of the argo that generated this form
        position, // the position of the argo that generated this form

        /* API functions */
        addArgo,
        editArgo,

        /* display and utils*/
        displayArgform,
        setDisplayArgform,
        formType,
        content, // use: editArgo - the content of the argo to edit
        placeholder,

        /* ref */
    } = props

    const [input, setInput] = useState("")

    // useEffect here to set the input to the text of the argument on edit; in other cases, it'll just set it to null.
    // the dependancy used is displayArgform which will change once the component is initiated.
    useEffect(() => {
        formType === "editArgo" ? setInput(content) : setInput("")
    }, [displayArgform])

    // if this is not true, the component will not render
    // displayArgform is controlled by either <DebateSection /> or  <Argo />
    if (!displayArgform) return null

    const handleChange = e => {
        setInput(e.target.value)
    }

    const handleSubmit = e => {
        e.preventDefault()

        // IMPORTANT: the position argument (usually 2nd argument)
        // in the following API refer to the position where
        // the generated argo will be, NOT where the argform generator is
        switch (formType) {
            case "addArgo":
                addArgo(input, { position: "argument", parentArgoId: null, inReplyTo: null })
                setInput("")
                break
            case "editArgo":
                editArgo(input, argoId)
                setDisplayArgform(d => !d)
                break
            case "addReply":
                position === "argument" || position === "main"
                    ? // ArgoId is the id of the argo that generates the form
                      // this will generate firstlevel reply
                      addArgo(input, { position: "reply", parentArgoId: argoId, inReplyTo: argoId })
                    : // the parentArgoId is the parent of the argoId:
                      // this is so that if the position = reply, then
                      // the reply will be associated with the parent of the argo,
                      // and not create and 2nd level of replies
                      addArgo(input, { position: "reply", parentArgoId, inReplyTo: argoId })

                setDisplayArgform(d => !d)
                setInput("")
                break
            default:
                console.log("Invalid")
        }
    }

    const handleKeyDown = e => {
        if (e.key === "Escape" && formType !== "addArgo") setDisplayArgform(d => !d)
    }

    return (
        // div tag instead of form tag because a form tag cannot be nested within another form tag:
        // this component is used in the Upload debate component, and this problem occurs there.
        <div className="add-argo-form">
            <TextareaAutosize
                onChange={handleChange}
                value={input}
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
            />
            <button id="add-argo-button" onClick={e => handleSubmit(e)}>
                {
                    // this renders custom messages depending on where the form is located and on its purpose
                    {
                        editArgo: "Submit",
                        addArgo: "ADD ARGUMENT",
                        addReply: "Reply",
                    }[formType]
                }
            </button>
        </div>
    )
}

export default Argform
