import styled from "styled-components"

export const Card = styled.div`
    margin: 1rem;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 40rem;
    max-width: 40rem;
    border-radius: 8px;
    border: 1px solid var(--white1);
    position: relative;
    background: linear-gradient(135deg, hsla(240, 41%, 92%, 0.3) 0%, hsla(240, 41%, 92%, 0.4) 100%);
    transition: 300ms linear;

    &:hover {
        cursor: default;
    }
`

export const H1 = styled.h1`
    /* used generally for the debate question */
    font-size: 1.5rem;
    margin: 0.5rem 0;
    color: var(--dark-blue);
    hyphens: manual;
`

export const Dropdown = styled.ul`
    all: unset;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1.5rem;
    right: 0;
    border-radius: 0.25rem;
    box-shadow: 0px 3px 3px -2px var(--dark-blue);
    min-width: 8.5rem; /* [HARDCODED] */
    outline: 1px solid var(--dark-blue);
    z-index: 100;
    background-color: var(--dark-blue);
    color: var(--white1);
`

export const DropdownItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 1rem;
    cursor: pointer;
    gap: 0.5rem;
    transition: 150ms linear;

    &:first-child {
        border-radius: 0.25rem 0.25rem 0 0;
    }

    &:last-child {
        border-radius: 0 0 0.25rem 0.25rem;
    }

    &:hover {
        background-color: var(--dark-blue-accent);
        font-weight: 600;
    }
`
