import { Outlet } from "react-router-dom"
import styled from "styled-components"

const Layout = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-between;
    background-color: var(--bg-grey);
    box-sizing: border-box;
`

const BaseLayout = () => {
    return (
        <Layout>
            <Outlet />
        </Layout>
    )
}

export default BaseLayout
