import { customFetch } from "../hooks/fetch"
import { useUserContext } from "../hooks/useUserContext"
import { useErrorHandler } from "../hooks/useErrorHandler"
import { useState } from "react"

export const useDeleteRequest = () => {
    const { user, dispatch } = useUserContext()
    const [isLoading, setIsLoading] = useState(null)
    const errorHandler = useErrorHandler() 

    return {
        isLoading, 
        argos: async function (deleteRequestObject) {
            try {
                const { path, payload, actions: {setArgos} } = deleteRequestObject
                const bearerToken = `Bearer ${user.token}`
        
                const { data, err } = await customFetch(path, {
                    method: "DELETE", 
                    token: bearerToken, 
                    payload
                })  
        
                if (err) throw err

                const { newArgo, userUpdate} = data //newUser is null her, but need to check. TODO
        
                if (newArgo.position === 'reply') {
                    setArgos(currentArgos => {
                        // newArgo.parentArgo returns the ID of the parent Argo 
                        const parentArgo = currentArgos.filter(argo => argo._id === newArgo.parentArgo)[0]
        
                        // spread the array like so, so that the comment appears at the beginning  
                        parentArgo.replies = parentArgo.replies.map(reply => reply._id !== newArgo._id ? reply : newArgo)
        
                        // return the new state, replacing the old argo with the new one 
                        return currentArgos.map(argo => argo._id !== parentArgo._id ? argo : parentArgo)
                    })
                } else {
                    // using map here because filter changes the order of the argos and it results in messy rerenders. 
                    setArgos(currentArgos => currentArgos.map(argo => argo._id !== newArgo._id ? argo : newArgo))
                }

                if (userUpdate) {
                    dispatch({type: "UPDATE_USER", payload: userUpdate})
                }

            } catch (error) {
                console.log(error)
                errorHandler(error)
            }
        },
        debates: async function (deleteRequestObject) {
            const { path, payload, actions: {setDebates} } = deleteRequestObject
            try {
                const bearerToken = `Bearer ${user.token}`
                
                const { data, err } = await customFetch(path, { 
                    method: "DELETE", 
                    token: bearerToken, 
                    payload
                })
                
                const { deletedDocument, userUpdate } = data
                if (err) throw err

                if (userUpdate) {
                    dispatch({type: "UPDATE_USER", payload: userUpdate})
                }

                setDebates(prevDebates => prevDebates.filter(debate => debate._id !== deletedDocument._id))
            } catch (error) {
                errorHandler(error)
            }
    
        }        
    }
}