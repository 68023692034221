import React, { useState, useEffect, useRef } from "react"
import { customFetch } from "../../hooks/fetch"
import { useUserContext } from "../../hooks/useUserContext"
import { useURLContext } from "../../hooks/useURLContext"
import "./User-styles.css"
import "./User.mobile.css"
import UserSection from "./UserSection"
import { defaultAvatarPresets } from "./avatarCardStyles"
import { useGetRequest } from "../../api/useGetRequest"
import AvatarCard from "./AvatarCard"
import { useCreateRequest } from "../../api/useCreateRequest"

const User = () => {
    const [selectedFile, setSelectedFile] = useState(null)
    const [ownedDebates, setOwnedDebates] = useState([])
    const [savedDebates, setSavedDebates] = useState([])
    const [sendEmailButtonDisplay, setSendEmailButtonDisplay] = useState(true)
    const [defaultAvatars, setDefaultAvatars] = useState([])
    const [selectedAvatar, setSelectedAvatar] = useState(null)
    const [isCustom, setIsCustom] = useState(false)

    const { user } = useUserContext()
    const { url } = useURLContext()
    const getRequest = useGetRequest()
    const createRequest = useCreateRequest()

    useEffect(() => {
        if (!user) return
        ;(async () => {
            const userId = user._id

            // owned debates api GET request
            await getRequest.users({
                path: `${url}/users/owned-debates/${userId}`,
                actions: {
                    setDebates: setOwnedDebates,
                },
            })

            // saved debates api GET request
            await getRequest.users({
                path: `${url}/users/saved-debates/${userId}`,
                actions: {
                    setDebates: setSavedDebates,
                },
            })

            await getRequest.users({
                path: `${url}/users/avatar/defaults/${userId}`,
                actions: {
                    setDefaultAvatars,
                },
            })
        })()
    }, [user])

    // set the default avatar
    useEffect(() => {
        if (!user) return
        const index = defaultAvatars.indexOf(user.avatar)
        if (index !== -1) {
            setSelectedAvatar(defaultAvatars[index])
        }
    }, [defaultAvatars])

    const hiddenUploadInput = useRef(null)

    const handleClick = () => {
        hiddenUploadInput.current.click()
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (!user) return
        const formData = new FormData()
        // todo: change the name of the file uploaded later
        formData.append("image", selectedFile)

        const path = isCustom ? `${url}/users/avatar/custom/${user._id}` : `${url}/users/avatar/defaults/${user._id}`

        await createRequest.users({
            path,
            payload: isCustom ? formData : { defaultAvatarS3Url: selectedAvatar },
            config: {
                stringifyPayload: !isCustom,
                removeContentType: isCustom,
            },
        })
    }

    const fileSelector = e => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = () => {
            setSelectedAvatar(reader.result)
        }
        reader.readAsDataURL(file)
        setIsCustom(true)
        setSelectedFile(file)
    }

    const handleEmailRequestButton = async () => {
        try {
            const { err } = await customFetch(`${url}/users/confirmation/resend/`, {
                method: "POST",
                token: null,
                payload: {
                    _id: user._id,
                    email: user.email,
                    userName: user.userName,
                },
            })

            if (err) throw err

            setSendEmailButtonDisplay(d => !d)

            // showNotification("EMAIL_SEND_SUCCESS")
        } catch (error) {
            // errorHandler(error.message)
        }
    }

    const toggleStyles = index => defaultAvatarPresets[index]

    if (!user) return null

    return (
        <main id="user-profile">
            <header className="user-profile-header">
                <span id="user-profile-chip">{user.userName}</span>
                <span id="user-insight-chip">Insight: {user.insightBudget}</span>
            </header>

            {/* my debates */}

            <UserSection sectionName={"Owned Debates"} debates={ownedDebates} />

            {/* Saved posts */}
            <UserSection sectionName={"Saved Debates"} debates={savedDebates} />

            {/* Profile */}
            <section className="user-section">
                <header className="profile-header">
                    <h1>Profile</h1>
                </header>
                <form className="avatar-upload">
                    <h4 className="avatar-upload-title">Choose your default profile picture</h4>
                    <ul className="default-avatar-list">
                        {defaultAvatars.length > 0 &&
                            defaultAvatars.map(awsURL => (
                                <AvatarCard
                                    key={awsURL}
                                    imgSrc={awsURL}
                                    styles={toggleStyles(defaultAvatars.indexOf(awsURL))}
                                    selectedAvatar={selectedAvatar}
                                    setSelectedAvatar={setSelectedAvatar}
                                    setIsCustom={setIsCustom}
                                />
                            ))}
                    </ul>
                    <span className="avatar-upload-separator">Or upload your own</span>
                    <img
                        src={selectedAvatar || user.avatar}
                        alt="profile picture"
                        className="user-supplied-avatar"
                        onClick={() => handleClick()}
                    />
                    <input
                        type="file"
                        onChange={fileSelector}
                        accept=".png, .jpg, .jpeg"
                        ref={hiddenUploadInput}
                        style={{ display: "none" }}
                    />
                    <button id="submit-avatar-button" onClick={e => handleSubmit(e)}>
                        Change profile picture
                    </button>
                </form>
            </section>

            {/* Settings */}
            <section className="user-section">
                <header className="profile-header">
                    <h1>Settings</h1>
                </header>
                <button className="delete-user-btn"> Delete your account </button>
                <p className="delete-user-notice"> Remember this action is irreversible! </p>
                {user.emailConfirmed === false && (
                    <span className="email-confirmation-notice">
                        {!sendEmailButtonDisplay ? "Email sent, check your inbox" : "Please confirm your email"}
                        {sendEmailButtonDisplay && (
                            <button className="email-confirmation-btn" onClick={() => handleEmailRequestButton()}>
                                Resend link
                            </button>
                        )}
                    </span>
                )}
            </section>
        </main>
    )
}

export default User

/**
 * FormData documentation:
 *  methods: https://developer.mozilla.org/en-US/docs/Web/API/FormData
 *  upload guides: https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
 *
 */
