import { createContext, useReducer, useEffect } from 'react'

export const UserContext = createContext()

export const userReducer = (state, action) => {
    switch(action.type) {
        case "LOGIN": 
            return {user: action.payload}
        case "LOGOUT": 
            return {user: null}
        case "UPDATE_USER": 
            // TODO: a check here to see if the prevuser and the current have the same number of keys. 
            // we do this because we return from the backend only the property that needs to be changed, (here it's represented by action.payload)
            localStorage.setItem("user", JSON.stringify({...state.user, ...action.payload})) // TODO: find a different solution, side effects can be harmful here
            return {user: {...state.user, ...action.payload}} // we return the new user 
        default: 
            return state
    }
}

export const UserContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(userReducer, {user: null})

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
    
        if (user) {
            dispatch({ type: 'LOGIN', payload: user })
        }

    }, [])

    return (
        <UserContext.Provider value={{...state, dispatch}}>
            {children}
        </UserContext.Provider>
    )

}