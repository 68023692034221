import React from 'react'

const ErrorPage = ({errorMessage}) => {
    return (
        <div>
           Something went wrong.
        </div>
    )
}

export default ErrorPage