import React from 'react'
import './Ratingchip-styles.css'

const RatingChip = (props) => {
    const { _id, hasVoted, rateArgo, rating } = props

    return (
        <div className="rating-chip">
            <button 
                id={hasVoted('agreeVote') ? "argo-like-button-pre" : "argo-like-button-post"}
                onClick={() => rateArgo('agree', _id)}
            > 
                {/* <FaThumbsUp /> */}
                <span className="argo-rating">{rating.agreeVote.length}</span>
            </button>
        
            {/* The negative rating button for an argument & the number */}
            <button 
                id={hasVoted('disagreeVote') ? "argo-dislike-button-pre" : "argo-dislike-button-post"}
                onClick={() => rateArgo('disagree', _id)}
            > 
                {/* <FaThumbsDown /> */}
                <span className="argo-rating">{rating.disagreeVote.length}</span>
            </button>
        </div>
    )
}

export default RatingChip