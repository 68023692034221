import React from "react"

const Owner = props => {
    const { creator, formattedDate, defaultAvatar } = props
    return (
        <section className="owner-chip">
            <img src={defaultAvatar} id="debate-avatar" alt="NF" />
            <span id="debate-creator">{creator?.userName || defaultAvatar}</span>
            <span id="debate-creation-date">{formattedDate}</span>
        </section>
    )
}

export default Owner
