import React, { useState, useEffect, useRef } from "react"
import Feedback from "../Feedback/Feedback.jsx"
// import AfterVote from "../Aftervote/Aftervote"
import DebateSection from "../DebateSection/DebateSection.jsx"
import "./Debate-styles.css"
import "./Debate.mobile.css"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { Card, H1 } from "./Debate-Styles.js"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { useCreateRequest } from "../../api/useCreateRequest.js"
import { usePatchRequest } from "../../api/usePatchRequest.js"
import { useDeleteRequest } from "../../api/useDeleteRequest.js"
import { useUserContext } from "../../hooks/useUserContext.js"
import { useGetRequest } from "../../api/useGetRequest.js"
import { useURLContext } from "../../hooks/useURLContext.js"
import { useScrollLock } from "../../hooks/useScrollLock.js"
import { useNotificationContext } from "../../hooks/useNotificationContext.js"
import { useNavigate } from "react-router-dom"
import { useErrorHandler } from "../../hooks/useErrorHandler.js"
import DebateMediaSection from "./DebateMediaSection/DebateMediaSection.jsx"
import { DOMNodeStorageContextProvider } from "../../context/DOMNodeStorageContext.js"
import { io } from "socket.io-client"
import Header from "./Header.jsx"

const Debate = props => {
    const {
        creator,
        createdAt,
        debateId,
        debateQuestion,
        image,
        youtubeURL,
        userInterest,
        increaseUserInterest,
        registerVote,
        yesVote,
        noVote,
        deleteDebate,
    } = props

    const { user } = useUserContext()
    const { width } = useWindowDimensions()
    const { url } = useURLContext()
    const { notificationDispatch } = useNotificationContext()
    const createRequest = useCreateRequest()
    const patchRequest = usePatchRequest()
    const deleteRequest = useDeleteRequest()
    const getRequest = useGetRequest()
    const navigate = useNavigate()
    const errorHandler = useErrorHandler()

    const [displayVote, setDisplayVote] = useState(false)
    const [displayInterestButton, setDisplayInterestButton] = useState(true)
    const [displayArgos, setDisplayArgos] = useState(false)
    const [hasVoted, setHasVoted] = useState(false)
    const [hasEntered, setHasEntered] = useState(false)
    const [mainArgos, setMainArgos] = useState([])
    const [argoHasPromoted, setArgoHasPromoted] = useState(false)
    const [currentDebaters, setCurrentDebaters] = useState(0)
    const [socket, setSocket] = useState(null)

    // useOutsideClickDetector(ref, () => {
    //     width < 480 && toggleScroll()
    //     setDisplayOptionsPanel(false)
    // })

    // sockets - join a room
    useEffect(() => {
        if (!hasEntered) return
        const socket = io(url)
        socket.on("connect", () => {
            console.log("Socket connected")
            setSocket(socket)
        })

        socket.emit("joinRoom", debateQuestion)

        socket.on("userHasJoined", data => {
            setCurrentDebaters(data.sockets)
        })
        socket.on("userHasLeft", data => {
            setCurrentDebaters(data.sockets)
        })

        socket.on("disconnect", () => {
            console.log("Socket Disconnected")
        })

        return () => {
            socket.off()
            socket.disconnect()
            setSocket(null)
        }
    }, [hasEntered])

    // get the creator's avator for the debate
    useEffect(() => {
        // determine if the user has already voted
        const found = user.votedOn.find(debate => debate.debateId.valueOf() === debateId)
        // console.log(user.votedOn)
        if (found) {
            setHasVoted(true)
        }
    }, [user])

    // this handles the
    useEffect(() => {
        if (argoHasPromoted) {
            ;(async () => {
                if (user.token) {
                    await renderMainArgos(debateId)
                }
            })()
            setArgoHasPromoted(false)
        }
    }, [argoHasPromoted])

    const rateArgo = async (value, argoId) => {
        await patchRequest.argos({
            path: `${url}/argos/rating/${argoId}`,
            payload: {
                value,
                userId: user._id,
            },
            actions: {
                setArgos: setMainArgos,
            },
        })
    }

    const editArgo = async (content, argoId) => {
        await patchRequest.argos({
            path: `${url}/argos/${argoId}`,
            payload: {
                userId: user._id,
                content,
            },
            actions: {
                setArgos: setMainArgos,
            },
        })
    }

    const addArgo = async (content, { position, parentArgoId, inReplyTo }) => {
        await createRequest.argos({
            path: `${url}/argos`,
            payload: {
                parentDebateId: debateId,
                userId: user._id,
                userName: user.userName,
                content,
                parentArgoId,
                position,
                inReplyTo,
            },
            actions: { setArgos: setMainArgos },
        })
    }

    const deleteArgo = async argoId => {
        await deleteRequest.argos({
            path: `${url}/argos/${argoId}`,
            payload: {
                userId: user._id,
            },
            actions: {
                setArgos: setMainArgos,
            },
        })
    }

    const spendInsight = async (insightAmount, argoId) => {
        await patchRequest.argos({
            path: `${url}/argos/insight/${argoId}`,
            payload: {
                insightAmount,
                userId: user._id,
            },
            actions: {
                setArgos: setMainArgos,
            },
        })
    }

    const showVote = () => {
        // check if the user is logged
        if (!user) {
            navigate("/login")
            return
        }
        setDisplayVote(prevDisplayVote => !prevDisplayVote)
        setDisplayInterestButton(prevdisplayInterestButton => !prevdisplayInterestButton)
        increaseUserInterest(debateId)
    }

    const renderMainArgos = async debateId => {
        await getRequest.argos({
            path: `${url}/argos/${debateId}`,
            position: "mainArgos",
            actions: {
                setArgos: setMainArgos,
            },
        })
    }

    const vote = async answer => {
        try {
            await renderMainArgos(debateId)

            setDisplayArgos(prevDisplayFacts => !prevDisplayFacts)
            setDisplayVote(prevDisplayVote => !prevDisplayVote)
            registerVote(debateId, answer)
            setHasEntered(hasEntered => !hasEntered)
        } catch (error) {
            errorHandler(error)
        }
    }

    const rejoinDebate = async () => {
        try {
            await renderMainArgos(debateId)
            setDisplayArgos(true)
            setHasEntered(true)
        } catch (error) {
            errorHandler(error)
        }
    }

    const leaveDebate = () => {
        socket.emit("leaveRoom", debateQuestion)
        socket.off()
        socket.disconnect()
        setSocket(null)
        setDisplayArgos(false)
        setHasEntered(false)
    }

    const addToSavedDebates = async () => {
        await patchRequest.users({
            path: `${url}/users/saved-debates/${debateId}`,
            payload: {
                userId: user._id,
            },
        })

        const alreadySaved = user.savedDebates.includes(debateId)

        notificationDispatch({
            type: "SUCCESS",
            payload: {
                notificationText: !alreadySaved ? "Debated Saved" : "Removed from saved debates",
            },
        })
    }

    const flagArgo = async argoId => {
        await patchRequest.argos({
            path: `${url}/argos/flags/${argoId}`,
            payload: {
                userId: user._id,
            },
            actions: {
                setArgos: null,
            },
        })

        notificationDispatch({
            type: "SUCCESS",
            payload: {
                notificationText: "Thank you for flagging this argument. Our team will take a look shortly.",
            },
        })
    }

    const viewership = () => {
        return userInterest.length === 1 ? `1 view` : `${userInterest.length} views`
    }

    dayjs.extend(relativeTime)
    const formattedDate = dayjs(createdAt).fromNow()

    return (
        <DOMNodeStorageContextProvider>
            {/* This is for storing refs for Argos */}
            {/* e.target === e.currentTarget excludes the children basically */}
            <Card className="debate" onClick={e => hasVoted && e.target === e.currentTarget && rejoinDebate()}>
                <Header creator={creator} formattedDate={formattedDate} />
                <H1>{debateQuestion}</H1> {/* Debate title */}
                <DebateMediaSection width={width} imageSrc={image} youtubeURL={youtubeURL} />
                {/* <MainArgosSection
                    user={user} //passing it here to avoid another hook call (makes things faster this way)
                    displayArgos={displayArgos}
                    mainArgos={mainArgos}
                    addArgo={addArgo}
                    deleteArgo={deleteArgo}
                    rateArgo={rateArgo}
                    editArgo={editArgo}
                    spendInsight={spendInsight}
                    flagArgo={flagArgo}
                /> */}
                {/* =================FEEDBACK================= */}
                <Feedback
                    vote={vote}
                    showVote={showVote}
                    displayVote={displayVote}
                    displayInterestButton={displayInterestButton}
                    hasVoted={hasVoted}
                    hasEntered={hasEntered}
                    setHasEntered={setHasEntered}
                    rejoinDebate={rejoinDebate}
                />
                {/* =================AFTERVOTE================= */}
                {/* {hasEntered && (
                    <AfterVote
                        debateId={debateId}
                        yesVote={yesVote}
                        noVote={noVote}
                        hasVoted={hasVoted}
                        hasEntered={hasEntered}
                    />
                )} */}
                {/* =================DEBATE SECTION================= */}
                {hasEntered && socket && (
                    <DebateSection
                        _id={debateId}
                        hasEntered={hasEntered}
                        setArgoHasPromoted={setArgoHasPromoted}
                        socket={socket}
                    />
                )}
            </Card>
        </DOMNodeStorageContextProvider>
    )
}

export default Debate
