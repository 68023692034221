import "./App.css"
import "./App.mobile.css"
import Navbar from "./components/Navbar/Navbar"
import Footer from "./components/Footer/Footer"
import { UserContextProvider } from "./context/UserContext"
import { URLContextProvider } from "./context/URLContext"
import { NotificationContextProvider } from "./context/NotificationContext"
import { Outlet } from "react-router-dom"
const version = "v0.15.2[alpha]"

function App() {
    return (
        <URLContextProvider>
            <NotificationContextProvider>
                <UserContextProvider>
                    <main>
                        <Navbar />
                        <Outlet />
                        <Footer version={version} />
                    </main>
                </UserContextProvider>
            </NotificationContextProvider>
        </URLContextProvider>
    )
}

export default App
