import { useLogout } from "./useLogout"
import { useNotificationContext } from "./useNotificationContext"
import { useNavigate } from "react-router-dom"

/**
 * @param {String} errorCode Error code such as USER_NOT_FOUND
 * @param {String} message A message to be dispatched to the UI
 * @returns {undefined}
 */

export const useErrorHandler = () => {
    const logout = useLogout()
    const navigate = useNavigate()
    const { notificationDispatch } = useNotificationContext()

    return (error) => {
        if (!error) return
        const { errorCode, message } = error // NOT errorMessage
        
        // token error 
        if (errorCode === "TOKEN_EXPIRED") {
            logout()
            navigate("/login")
        }

        // console.log(errorCode, message)
        // console.log(error)

        notificationDispatch({
            type: "ERROR",
            payload: {
                notificationText: message
            }
        })

    }
    
}  