import React from 'react'
import { useNavigate } from 'react-router-dom'
import './DebateCard-styles.css'

const DebateCard = (props) => {

    const { debateQuestion, debateId, yesVote, noVote } = props

    const navigate = useNavigate()

    const handleClick = () => {
        // clicking on the debate card will rebuild DebateList view
        navigate(`/debates/${debateId}`) 
    }

    return (
        yesVote && noVote &&
        <li className="debate-card" onClick={() => handleClick()}>
            {/* <img src={logo} /> */}
            <section className="inside-debate-card-border">
                {debateQuestion}
            </section>
            {/* <section className="outside-debate-card-border">
                <span> <FaThumbsUp /> {yesVote.length} </span>
                <span> <FaThumbsDown /> {noVote.length} </span>
            </section> */}
        </li>

    )
}

export default DebateCard