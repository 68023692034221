import { customFetch } from "../hooks/fetch"
import { useUserContext } from "../hooks/useUserContext"
import { useState } from 'react'
import { useNotificationContext } from '../hooks/useNotificationContext'
import { useErrorHandler } from "../hooks/useErrorHandler"

export const useCreateRequest = () => {
    const { user, dispatch } = useUserContext()
    const [isLoading, setIsLoading] = useState(null)
    const { notificationDispatch } = useNotificationContext()
    const errorHandler = useErrorHandler()
    
    return {
        isLoading,
        argos: async function (postObject) {
            // destructuring the options object 
            const { path, payload, actions: {setArgos} } = postObject
            
            // Validations and notifications 
            if (payload.content.length < 1) throw new Error("EMPTY_INPUT")
            
            // API call 
            try {
                const bearerToken = `Bearer ${user.token}`
                const { data, err } = await customFetch(path, {
                    method: "POST",
                    token: bearerToken,
                    payload: {
                        ...payload,
                        creator: user._id
                    }}
                )
        
                const { newArgo, userUpdate } = data

                // first error is the server error 
                if (err) throw err
        
                if (newArgo.position === 'reply') {
                    setArgos(currentArgos => {
                        // newArgo.parentArgo returns the ID of the parent Argo 
                        const parentArgo = currentArgos.filter(argo => argo._id === newArgo.parentArgo)[0]
        
                        // spread the array like so, so that the comment appears at the beginning  
                        parentArgo.replies = [newArgo, ...parentArgo.replies]
        
                        // return the new state, replacing the old argo with the new one 
                        return currentArgos.map(argo => argo._id !== parentArgo._id ? argo : parentArgo)
                    })
                } else {
                    setArgos(currentArgos => [newArgo, ...currentArgos])
                }
        
                // update the user 
                if (userUpdate) {
                    dispatch({type: "UPDATE_USER", payload: userUpdate})
                }

            } catch(error) {
                errorHandler(error)
            }
        },
        debates: async function (postObject) {
            setIsLoading(true)
            const { path, payload, removeContentType, additionalHeader, stringifyPayload, actions: {setDebates, setHasUploaded} } = postObject
            
            try {
                const bearerToken = `Bearer ${user.token}`
                const { data, err } = await customFetch(path, { 
                    method: "POST", 
                    token: bearerToken, 
                    payload
                }, {removeContentType, stringifyPayload, additionalHeader})    
                if (err) throw err
                
                const { newDebate, userUpdate } = data

                if (userUpdate) {
                    dispatch({type: "UPDATE_USER", payload: userUpdate})
                }
                
                if (setDebates) {
                    setDebates(previousPosts => [...previousPosts, newDebate])
                } 
                // because the upload function is in a different component than all the others 
                // I will just rerender the <DebateList> component by change this state
                if (setHasUploaded) {
                    setHasUploaded(state => !state)
                }

                notificationDispatch({
                    type: "SUCCESS",
                    payload: {
                        notificationText: "Your debate has been submitted successfully!"
                    }
                })

                setIsLoading(false)
            } catch (error) {
                console.log(error)
                errorHandler(error)
            }
        },
        users: async function (postObject) {
            const {path, payload, config: {stringifyPayload, removeContentType}} = postObject
            
            try {
                const bearerToken = `Bearer ${user.token}`

                const requestParams = {
                    method: "POST", 
                    token: bearerToken,
                    payload
                }

                const config = {
                    stringifyPayload, //formdata does not need to be stringified 
                    removeContentType
                }
                
                const { data, err } = await customFetch(path, requestParams, config)
                
                const { userUpdate } = data
                if (err) throw err

                if (userUpdate) {
                    dispatch({type: "UPDATE_USER", payload: userUpdate})
                }

            } catch (error) {
                errorHandler(error)
            }
        }
    }
}
