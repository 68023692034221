import { customFetch } from "../hooks/fetch"
import { useUserContext } from "../hooks/useUserContext"
import { useState } from "react"
import { useErrorHandler } from "../hooks/useErrorHandler"

export const useGetRequest = () => {
    const { user } = useUserContext()
    const errorHandler = useErrorHandler()
    const [isLoading, setIsLoading] = useState(null)

    /**
     * @param {Array} data a collection of argos 
     * @param {Object} getObject options object for the api call
     * @returns {function} the API to be used
    */
    
    return {
        isLoading,
        argos: async function (getObject) {
            setIsLoading(true)
            const { path, position="argos", actions: {setArgos} } = getObject
            
            try {
                const bearerToken = `Bearer ${user.token}`
                
                const { data, err } = await customFetch(path, {
                    method: "GET", 
                    token: bearerToken
                }, {
                    additionalHeader: {
                        position
                    },
                })
                
                if (err) throw err
                setArgos(data)
                setIsLoading(false)
            } catch (error) {
                errorHandler(error)
            }
        }, 
        debates: async function (getObject) {
            setIsLoading(true)
            const {path, additionalHeader, actions: {setDebates}} = getObject
            
            try {
                const { data: debateList, err } = await customFetch(path, {
                    method: "GET"
                }, {
                    additionalHeader
                })

                if (err) throw err
                setDebates(debateList)
                setIsLoading(false)

            } catch (error) {
                errorHandler(error)
            }
        },
        users: async function (getObject) {
            setIsLoading(true)
            const {path, additionalHeader, actions: {setDebates, setDefaultAvatars}} = getObject
            
            try {
                const bearerToken = `Bearer ${user.token}`

                const { data, err } = await customFetch(path, {
                    method: "GET",
                    token: bearerToken,
                    additionalHeader
                })
                if (err) throw err

                // switch that evaluates if any of the actions exist 
                // the !! converts to boolean values 
                // documentation: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/switch
                // this is used if the actions are mutually exclusive 
                switch (true) {
                    case !!setDebates:
                        setDebates(data)
                        break
                    case !!setDefaultAvatars: 
                        setDefaultAvatars(data)
                        break
                    default:
                        throw new Error("What is going on.")
                }

                setIsLoading(false)
            } catch (error) {
                errorHandler(error)
            }

        }
    }
}