import { useEffect } from "react"

/**
 * @param {React.Ref} ref a Ref to a dom element from React
 * @param {funciton} actions a function that includes a collection of functions that are called inside it
 * Hook that performs actions when user clicks outside of the passed ref
 */
export const useOutsideClickDetector = (ref, actions) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                actions()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])
}
