import { URLContext } from "../context/URLContext"
import { useContext } from "react"

/**
 * @returns {context | Object} basically this is a wrapper that consumes the context and checks if the context exists before returning 
 * it within a hook
 */

export const useURLContext = () => {
    const context = useContext(URLContext)

    // TODO: error handling on the FE 
    if (!context) {
        throw new Error("No context found")
    }

    return context
}