import React, { useState, useRef } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useLogout } from "../../hooks/useLogout"
// note order of imports matters, mobile has to come second all the time otherwise it does not take priority for some reason
import "./Navbar-styles.css"
import "./Navbar.mobile.css"
import { useUserContext } from "../../hooks/useUserContext"
import { useScrollLock } from "../../hooks/useScrollLock"
import { useOutsideClickDetector } from "../../hooks/useOutsideClickDetector"

const Navbar = ({ setModalDisplay }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useUserContext()
    const logout = useLogout()
    const toggleScroll = useScrollLock()
    const ref = useRef()

    const [profileDropdown, setProfileDropdown] = useState(false)

    useOutsideClickDetector(ref, () => setProfileDropdown(false))

    const refreshPage = () => {
        // if the location is the homepage, just refresh the page with navigate(0)
        if (location.pathname === "/") {
            navigate(0)
        } else {
            // otherwise just send the user to the homepage
            navigate("/")
        }
    }

    // const displayTutorial = () => {
    //     window.scrollTo(0, 0)
    //     setModalDisplay(t =>!t)
    //     toggleScroll()
    // }

    return (
        <nav className="navbar">
            <section className="profile-section">
                {user ? (
                    <div className="user-profile-section">
                        <img src={user.avatar} alt="user profile" onClick={() => setProfileDropdown(d => !d)} />

                        {/* <ul ref={ref} className={profileDropdown ? "profile-dropdown" : "disabled"}>
                            <li className={profileDropdown ? "navbar-item" : "disabled"} id="userbox"> {user.userName} </li>
                            <hr className="menu-divider"></hr>
                            <li onClick={() => {setProfileDropdown(d => !d); navigate("/user")}}> 
                                <AiOutlineUser size={20} /> Profile 
                            </li>
                            <li onClick={() => {setProfileDropdown(d => !d); navigate("/user")}}>
                                <FaBullhorn size={20} />My Debates
                            </li>
                            <li onClick={() => {setProfileDropdown(d => !d); navigate("/user")}}> 
                                <FaCog size={20} /> Settings
                            </li>
                            <hr className="menu-divider"></hr>
                            <li className="logout-button" onClick={() => { logout(); refreshPage() }}> Log out </li>
                        </ul> */}
                    </div>
                ) : (
                    <>
                        <Link to="/login" style={{ textDecoration: "none" }}>
                            <button className="sign-in-button" name="signin-btn">
                                {" "}
                                Sign in{" "}
                            </button>
                        </Link>
                    </>
                )}
            </section>
        </nav>
    )
}

export default Navbar
