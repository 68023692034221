export const defaultAvatarPresets = [
    {
        backgroundColor: "#c03421"
    },
    {
        backgroundColor: "#3A74B4"
    },
    {
        backgroundColor: "#996B9F"
    }
]

