import { createContext, useReducer, useEffect, useState } from 'react'

export const NotificationContext = createContext()

export const notificationsReducer = (state, action) => {
    switch(action.type) {
        case "SUCCESS": 
            return {
                notificationType: "SUCCESS", 
                notificationText: action.payload.notificationText,
            }
        case "ERROR": 
            return {
                notificationType: "ERROR", 
                notificationText: action.payload.notificationText,
            }
        default: 
            return state
    }  
}

export const NotificationContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(notificationsReducer)
    const [displayNotification, setDisplayNotification] = useState(false)
    
    useEffect(() => {
        if (!state) return
        setDisplayNotification(true)
    }, [state])
    
    return (
        <NotificationContext.Provider value={{...state, notificationDispatch: dispatch, displayNotification, setDisplayNotification}}>
            {children}
        </NotificationContext.Provider>
    )

}