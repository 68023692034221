import React from 'react'
import DebateCard from './DebateCard'

const UserSection = (props) => {
    const { sectionName, debates } = props

    const debateList = () => {
        return debates.map(item =>
            <DebateCard
                key={item._id}
                debateId={item._id}
                debateQuestion={item.debateQuestion}
                yesVote={item.yesVote}
                noVote={item.noVote}
            />
        )
    }

    return (
        <section className='user-section'>
            <header className='debates-header'>
                <h1>{sectionName}</h1>
            </header>

            <ul className="debate-list">
                {debateList()}
            </ul>
        </section>
    )
}

export default UserSection