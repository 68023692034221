import React from 'react'
import { useEffect, useState } from 'react'
import './AvatarCard-styles.css'


const AvatarCard = (props) => {
    
    const { imgSrc, styles, setSelectedAvatar, selectedAvatar, setIsCustom } = props
    const [saturation, setSaturation] = useState("25%")

    const handleClick = () => {
        setSelectedAvatar(imgSrc)
        setIsCustom(false)
    }

    useEffect(() => {
        if (selectedAvatar === imgSrc) {
            setSaturation("100%")
        } else {
            setSaturation("25%")
        }
    }, [selectedAvatar])

    return (
        <li className='default-avatar-card' style={{...styles, filter: `saturate(${saturation})`}} onClick={() => handleClick()}>
            <div className='default-avatar-card-top'></div>
            <img className="avatar-card-img" src={imgSrc}></img>
        </li>
    )
}

export default AvatarCard