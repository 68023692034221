import React from "react"

const Content = props => {
    const { content, isEdited } = props

    return (
        <p id="argo-content">
            {content} {/* the body of text in the argument */}
            {/* if isEdited is true, add and "[edited]" tag */}
            <span> {isEdited && "[edited]"} </span>
        </p>
    )
}

export default Content
