import React, { useState, useEffect } from "react"
import "./DebateSection-styles.css"
import Argo from "../Argo/Argo"
import Argform from "../Argo/Argform"
import { useCreateRequest } from "../../api/useCreateRequest"
import { usePatchRequest } from "../../api/usePatchRequest"
import { useDeleteRequest } from "../../api/useDeleteRequest"
import { useUserContext } from "../../hooks/useUserContext"
import { useGetRequest } from "../../api/useGetRequest"
import { useURLContext } from "../../hooks/useURLContext"

const DebateSection = props => {
    const { _id: parentDebateId, hasEntered, setArgoHasPromoted, socket } = props

    // this sets the input for the comment section
    const displayArgform = true

    // this will be our main store for the arguments
    const [argoList, setArgoList] = useState([])

    const { user, dispatch } = useUserContext()
    const { url } = useURLContext()
    const createRequest = useCreateRequest()
    const patchRequest = usePatchRequest()
    const deleteRequest = useDeleteRequest()
    const getRequest = useGetRequest()

    // api call for the argos at this specific debate
    useEffect(() => {
        // this is because react's retardation is endless with their async useStates.
        // optimising performance my ass.
        if (!user) return

        if (hasEntered) {
            // no need to for await here -- this is because we're not returning anything in the getRequest
            getRequest.argos({
                path: `${url}/argos/${parentDebateId}`,
                position: "argos",
                actions: {
                    setArgos: setArgoList,
                },
            })
        }
    }, [parentDebateId])

    useEffect(() => {
        socket.on("insightUpdate", data => {
            const { userUpdate } = data
            getRequest.argos({
                path: `${url}/argos/${parentDebateId}`,
                position: "argos",
                actions: {
                    setArgos: setArgoList,
                },
            })
            if (userUpdate) {
                dispatch({ type: "UPDATE_USER", payload: userUpdate })
            }
        })
    }, [socket])

    // addArgo will make the POST api call
    const addArgo = async (content, { position, parentArgoId, inReplyTo }) => {
        await createRequest.argos({
            path: `${url}/argos`,
            payload: {
                parentDebateId,
                content,
                parentArgoId,
                position,
                inReplyTo,
            },
            actions: { setArgos: setArgoList },
        })
    }

    const deleteArgo = async argoId => {
        await deleteRequest.argos({
            path: `${url}/argos/${argoId}`,
            payload: {
                userId: user._id,
            },
            actions: {
                setArgos: setArgoList,
            },
        })
    }

    const editArgo = async (content, argoId) => {
        await patchRequest.argos({
            path: `${url}/argos/${argoId}`,
            payload: {
                userId: user._id,
                content,
            },
            actions: {
                setArgos: setArgoList,
            },
        })
    }

    const rateArgo = async (value, argoId) => {
        await patchRequest.argos({
            path: `${url}/argos/rating/${argoId}`,
            payload: {
                value,
                userId: user._id,
            },
            actions: {
                setArgos: setArgoList,
            },
        })
    }

    const spendInsight = async (insightAmount, argoId) => {
        // perhaps merge http request with socket request
        await patchRequest.argos({
            path: `${url}/argos/insight/${argoId}`,
            payload: {
                insightAmount,
                userId: user._id,
            },
            actions: {
                setArgos: null,
            },
        })
    }

    const promoteArgo = async argoId => {
        // use sockets here
        /**
         * promotes argos to mainArgos
         */
        const data = await patchRequest.argos({
            path: `${url}/argos/promote/${argoId}`,
            payload: {
                userId: user._id,
                parentDebateId,
            },
            actions: {
                setArgos: null,
            },
        })

        // custom logic:
        // extract newArgo and userUpdate from data returned by the crud hook
        const { newArgo, userUpdate } = data

        // remove the argument from the debate list
        setArgoList(currentArgos => currentArgos.filter(argo => argo._id !== newArgo._id))

        // notify the Debate component that the argument has promoted, which means that it will have to rerender.
        // on rerender, the useEffect will call the database and the mainArgos should render
        // The backend will autmatically change the position of the argument to 'main'
        // the argument should now be in the mainArgos instead, after rerender.
        setArgoHasPromoted(true)

        // the insight budget for the user has suffered a deduction as a result.
        if (userUpdate) {
            dispatch({ type: "UPDATE_USER", payload: userUpdate })
        }
    }

    const flagArgo = async argoId => {
        await patchRequest.argos({
            path: `${url}/argos/flags/${argoId}`,
            payload: {
                userId: user._id,
            },
            actions: {
                setArgos: null,
            },
        })
    }

    const promoteReply = async argoId => {
        /**
         * promotes replise to argos
         */
        const data = await patchRequest.argos({
            path: `${url}/argos/promote/replies/${argoId}`,
            payload: {
                userId: user._id,
                parentDebateId,
            },
            actions: {
                setArgos: null,
            },
        })

        // custom logic:
        // extract newArgo and userUpdate from data returned by the crud hook
        const { newArgos, userUpdate } = data

        // remove the argument from the debate list
        setArgoList(newArgos)

        // notify the Debate component that the argument has promoted, which means that it will have to rerender.
        // on rerender, the useEffect will call the database and the mainArgos should render
        // The backend will autmatically change the position of the argument to 'main'
        // the argument should now be in the mainArgos instead, after rerender.
        setArgoHasPromoted(true)

        // the insight budget for the user has suffered a deduction as a result.
        if (userUpdate) {
            dispatch({ type: "UPDATE_USER", payload: userUpdate })
        }
    }

    const getTotalInsight = () => {
        if (argoList.length) {
            const values = argoList.map(argo => argo.insightScore)
            const sum = values.reduce((a, b) => a + b)
            return sum
        }
        return 0
    }

    return (
        <div className="comment-section">
            <Argform
                key="main-argument-form"
                displayArgform={displayArgform}
                addArgo={addArgo}
                placeholder="Join the conversation, add an argument"
                formType="addArgo"
                position="argument" // main - argument - reply
            />

            <ul className="comment-list">
                {argoList.map(argo => (
                    <Argo
                        /* React identifier */
                        key={argo._id}
                        /* Database Model props */
                        {...argo}
                        totalInsight={getTotalInsight()}
                        /* API functions */
                        addArgo={addArgo}
                        deleteArgo={deleteArgo}
                        rateArgo={rateArgo}
                        editArgo={editArgo}
                        promoteArgo={promoteArgo}
                        promoteReply={promoteReply}
                        spendInsight={spendInsight}
                        flagArgo={flagArgo}
                        /* socket */
                        socket={socket}
                    />
                ))}
            </ul>
        </div>
    )
}

export default DebateSection
