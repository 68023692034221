import Upload from "../components/Upload/Upload"
import Debates from "../components/Debates/Debates"

const Feed = () => {
    return (
        <>
            <Upload />
            <Debates />
        </>
    )
}

export default Feed
