import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './Upload-styles.css'
import './Upload.mobile.css'
import Argo from '../Argo/Argo'
import Argform from '../Argo/Argform'
import { useUserContext } from '../../hooks/useUserContext'
import { useCreateRequest } from '../../api/useCreateRequest'
import { v4 as uuidv4 } from 'uuid'
import { useURLContext } from '../../hooks/useURLContext'
import youtubeURLParser from '../../utilities/youtube-url-parser.js'
import { useErrorHandler } from '../../hooks/useErrorHandler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import logo from "../../assets/logo.svg"

const Upload = ({ setHasUploaded }) => {
    const { user } = useUserContext()
    const { url } = useURLContext()
    const navigate = useNavigate()
    const errorHandler = useErrorHandler()
    const createRequest = useCreateRequest()
    
    const hiddenInputElement = useRef()
    const youtubeInputElement = useRef()
    
    const [argo, setArgo] = useState({
        creator: "",
        content: "",
        position: "argo",
        isLocked: true,
        isEdited: false,
        initialId: null,
        rating: {
            agreeVote: [],
            disagreeVote: []
        },
        canPromote: false,
        hasBeenDeleted: false,
        parentPost: null,
        parentArgo: null,
        insightScore: 150
    })

    const [debate, setDebate] = useState({
        creator: "",
        debateQuestion: "",
        argos: [],
        image: null,
        rawFile: null,
        youtubeURL: ""
    })

    const [displayArgform, setDisplayArgform] = useState(false)
    const [placeholder, setPlaceholder] = useState("What is the topic of your debate?")
    const [displayYoutube, setDisplayYoutube] = useState(false)
    const [youtubeLinkSuccessful, setYoutubeLinkSuccessful] = useState(null)

    useEffect(() => {
        if (!user) return
        
        setDebate({
            ...debate, 
            creator: user._id,
        })
    }, [user])

    // I need to use a 2nd useEffect, because the order of the states updated isn't always respected,
    // so a common problem I came across was that the debate was updating first and the mainArgo later, 
    // which sent empty content argos to the database.
    useEffect(() => {
        // Need to condition this because otherwise, this will add argos that have empty contents
        if (argo.content) {
            setDebate({...debate, argos: [...debate.argos, argo]})
        }
    }, [argo])

    const handleSubmit = async e => {

        e.preventDefault()
        const hasImage = debate.image
        // for image uploads I'm using a formdata; if no images, I use json 
        const formData = new FormData()
        // need to send this additional header to the server, so I know how to parse req.body()
        const additionalHeader = hasImage ? {hasImage: true} : null

        // 
        if (hasImage) {
            // append the images to the formdata 
            formData.append("image", debate.rawFile)
            // don't need these in the req.body()
            delete debate.image
            delete debate.rawFile
            // have to stringify the rest of the debate, because if I add a simple ("payload": debate) the return is [Object object]
            formData.append("payload", JSON.stringify(debate))
        }

        // delete the rawFile anyway, no need for it on the server 
        delete debate.rawFile

        await createRequest.debates({
            path: `${url}/debates`,
            actions: {
                setHasUploaded
            },
            removeContentType: hasImage, // formData() automatically sets content-type: formdata/multi-part
            stringifyPayload: !hasImage, // need to tell not to stringify the payload if we send a formdata  
            additionalHeader,
            payload: hasImage ? formData : debate
        })

        navigate('/')
    }

    const handleChange = e => {
        const name = e.target.name
        let value = e.target.value
        let youtubeURL = new String()
        
        if (name === "youtubeURL") {
            // if there is no value, need to return from this function and set the linkSuccessful to null again
            if (value.length === 0) {
                setYoutubeLinkSuccessful(null)
                return
            }

            youtubeURL = youtubeURLParser(e.target.value)

            if (!youtubeURL) {
                setYoutubeLinkSuccessful(false)
                return errorHandler({errorCode: "YOUTUBE_URL_INVALID", message: "The youtube URL is not valid, please try again." })
            }
            value = youtubeURL
            setYoutubeLinkSuccessful(true)
            console.log(value)
            // remove the image if a video is selected
            setDebate({...debate, youtubeURL: value, image: null, rawFile: null})
        } else {
            setDebate({...debate, [name]: value})
        }

    }

    const addArgo = input => {
        setDisplayArgform(d => !d)
        const generatedId = uuidv4()
        setArgo({
            ...argo,
            creator: user._id,
            content: input,
            initialId: generatedId
        })
    }

    const removeRenderedFact = (id) => {
        setDebate(prevDebate => {
            const filteredArgos = prevDebate.argos.filter(argo => argo.initialId !== id)
            return {...prevDebate, argos: filteredArgos}
        })
    }


    const fileSelector = e => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = () => {
            setDebate({
                ...debate,
                youtubeURL: null,
                image: reader.result,
                rawFile: file
            })
        }
        reader.readAsDataURL(file)

        /**
         * I've written the code for some functionality later on, perhaps it'll be usable then
         */
        // for (const file of files) {
        //     const reader = new FileReader()
        //     reader.readAsDataURL(file)
        //     reader.onload = () => {
        //         setDebate(prevDebate => {
        //             return {
        //                 ...prevDebate, images: [...prevDebate.images, reader.result]
        //             }
        //         })
        //     }
        // }
    }

    const handleClick = () => {
        hiddenInputElement.current.click()
    }

    return (
        <div className="upload">
            {/* <h2> What is the topic of your debate </h2> */}
            <form className="upload-form">
                <img id='upload-logo' src={logo} alt="logo" />
                <textarea
                    name="debateQuestion" 
                    id="debate-question" 
                    placeholder={placeholder}
                    onChange={handleChange}
                    required
                    rows={2}
                    maxLength="150"
                    onFocus={() => setPlaceholder("")}
                    onDoubleClick={() => setDisplayArgform(true)}
                    onBlur={() => setPlaceholder("Start a debate")}
                />

                <FontAwesomeIcon icon={faPaperPlane} id="final-submit-button" onClick={e => handleSubmit(e)}/>
            </form>
        </div>
    )
}

export default Upload
// {/* This is the input */}
// <Argform
//     displayArgform={displayArgform}
//     setDisplayArgform={setDisplayArgform}
//     placeholder={"Get things started by adding main arguments!"}
//     formType="addArgo"
//     position="argument"
//     id="add-fact-input" 
//     addArgo={addArgo}
//     onKeyDown={(e) => {  
//         if(e.key === "Escape") setDisplayArgform(d => !d)
//     }}
// />
// {/* The rendered facts section */}
// <ul className="rendered-facts-section">
//     {
//         debate.argos && debate.argos.map(argo =>
//             <section key={`li-${argo.initialId}`}> 
//                 <Argo
//                     key={argo.initialId}
//                     creator={argo.creator}
//                     content={argo.content}
//                     isLocked={argo.isLocked}
//                     position = {argo.position}
//                     isEdited={argo.isEdited}
//                     rating={argo.rating}
//                     canPromote={argo.canPromote}
//                     hasBeenDeleted={argo.hasBeenDeleted}
//                     parentPost={argo.parentPost}
//                     parentArgo={argo.parentArgo}
//                     insightScore={argo.insightScore}
//                 />
//                 <ImBin key={`del-btn-${argo.initialId}`} onClick={() => removeRenderedFact(argo.initialId)} />
//             </section>
//         )
//     }
// </ul>
// {/* The add images section */} 
// {   
//     !debate.youtubeURL && !displayYoutube && 
//     <div className='upload-subsection debate-image-upload'>
//         <BsFileEarmarkImage  onClick={() => handleClick()} /> 
//         <input type='file' onChange={fileSelector} accept=".png, .jpg, .jpeg" ref={hiddenInputElement} style={{display: "none"}}/>
//         {/* I'm leaving the functionality here, but I think one image should be enough for now */}
//         {
//             debate.image && 
//             <ul className='upload-debate-images-list'>
//                 <li key={uuidv4()} className="upload-debate-image-item"> 
//                     <img src={debate.image}/> {/* The image */}

//                     {/* The close buttons */}
//                     <AiTwotoneDelete onClick={() => {
//                         setDebate({...debate, image: null})
//                         hiddenInputElement.current.value = "" // otherwise the input file will not register a change when you upload same picture 
//                     }}/>
//                 </li>
//             </ul>
//         }
//     </div>
// }

// {
//     /* Add Youtube link section */
//     !debate.image && 
//     <div className='upload-subsection add-youtube-section'>
//         <AiFillYoutube  onClick={() => {
//         setDisplayYoutube(d => !d)
//         setYoutubeLinkSuccessful(null)
//         setDebate({...debate, youtubeURL: ""})}
//     }
//         />                        
//         {
//             displayYoutube && <section className='display-youtube-section'>
//                 <label htmlFor="youtube-link"><FiLink2 style={{
//                         backgroundColor: youtubeLinkSuccessful 
//                             ? "hsl(154, 88%, 28%)" // successful
//                             : youtubeLinkSuccessful === false
//                                 ? "hsl(0, 69%, 41%)" // not successful 
//                                 : "hsl(0, 0%, 71%)" // null 
//                     }}/>
//                 </label>
//                 <input id="youtube-link-input" ref={youtubeInputElement} name="youtubeURL" type="text" onChange={handleChange}/>
//             </section>
//         }
//     </div>
// }