import react, { useRef, useState, useEffect } from "react"
import "./DebateMediaSection-styles.css"

const DebateMediaSection = props => {
    const { imageSrc, youtubeURL } = props

    // this ensures that the youtube video fills the entire container
    const [videoWidth, setVideoWidth] = useState(0)
    const videoRef = useRef()
    useEffect(() => {
        if (videoRef.current) {
            setVideoWidth(videoRef.current.offsetWidth)
        }
    }, [videoRef.current])

    return (
        <section className="debate-media-section">
            {imageSrc && (
                <section className="debate-content">
                    <img className="debate-image" src={imageSrc} />
                </section>
            )}
            {
                // youtubeURL &&
                // <section className='debate-video' ref={videoRef}>
                //     <iframe
                //         height="315"
                //         width={videoWidth}
                //         src={`https://www.youtube.com/embed/${youtubeURL}`}
                //         title="YouTube video player"
                //         autoPlay="1"
                //         allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                //     </iframe>
                // </section>
            }
        </section>
    )
}

export default DebateMediaSection
