/**
 * @param {String} youtubeURL self-explanatory, the URL for a youtube video. 
 * see a list of formats here: 
 * https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486
 * stolen from: https://regexr.com/3dj5t
 * @returns { String } the videoId 
*/

export default function youtubeURLParser(youtubeURL) {
    // stolen from: https://regexr.com/531i0
    const regex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/

    try {
        const videoId = youtubeURL.match(regex)[5]  
        // this normally returns an array, value[5]
        return videoId
    } catch (error) {
        // return an empty string, because react can't handle nulls 
        return ""
    }

} 