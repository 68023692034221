import { createContext, useRef } from 'react'

export const DOMNodeStorageContext = createContext()

export const DOMNodeStorageContextProvider = ({children}) => {
    
    // attach a ref 
    const refsMap = useRef()
    refsMap.current = new Map()

    const addRef = (_id, node) => refsMap.current.set(_id, node)
    const getRef = (_id) => refsMap.current.get(_id)

    return (
        <DOMNodeStorageContext.Provider value={{addRef, getRef}}>
            {children}
        </DOMNodeStorageContext.Provider>
    )

}