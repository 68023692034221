import { createContext } from 'react'

export const URLContext = createContext()

export const URLContextProvider = ({children}) => {

    const url = process.env.NODE_ENV === "development" ? 'http://localhost:8080' : process.env.REACT_APP_PRODUCTION_BACKEND_URL

    return (
        <URLContext.Provider value={{url}}>
            {children}
        </URLContext.Provider>
    )

}