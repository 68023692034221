import React, { useState, useEffect } from "react"
import "./Argo-styles.css"
import "./Argo.mobile.css"
import placeholderAvatar from "../../pictures/default-pp.jpg"
import dayjs from "dayjs"
import Author from "./Author"
import Content from "./Content"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { useUserContext } from "../../hooks/useUserContext"
import { useErrorHandler } from "../../hooks/useErrorHandler"
import { useDOMNodeStorageContext } from "../../hooks/useDOMNodeStorageContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsis } from "@fortawesome/free-solid-svg-icons"
import ArgoHeader from "./ArgoHeader"
import RatingChip from "./RatingChip"
import Replies from "./Replies"

const Argo = props => {
    const {
        /* Database Model props */
        _id,
        creator,
        rating,
        content,
        position,
        isEdited,
        isLocked,
        hasBeenDeleted,
        parentArgoId,
        replies,
        createdAt,
        insightScore,
        canPromote,
        totalInsight,
        disableReplies,
        inReplyTo,

        /* Database Model props */

        /* API functions */
        addArgo,
        deleteArgo,
        rateArgo,
        editArgo,
        promoteArgo,
        promoteReply,
        spendInsight,
        flagArgo,

        /* socket */
        socket,
    } = props

    const { user } = useUserContext()
    const { width } = useWindowDimensions()
    const { addRef, getRef } = useDOMNodeStorageContext()
    const errorHandler = useErrorHandler()

    // this is to display the buttons when the use hovers on a argo
    const [displayArgform, setDisplayArgform] = useState(false)

    // in case the creator is deleted, or there are errors with the creator, display something specific
    const [creatorPlaceholder, setCreatorPlaceholder] = useState({})

    // display owner options or not
    const [ownerOptions, setOwnerOptions] = useState(false)
    const [formType, setFormType] = useState("")
    // this displays them if the user clicks on the three dotted button
    const [displayOwnerOptions, setDisplayOwnerOptions] = useState(false)
    const [displayInReplyTo, setDisplayInReplyTo] = useState(false)

    useEffect(() => {
        // check if creator exists, otherwise creator._id will throw
        creator && user._id === creator._id && setOwnerOptions(true)
    }, [user])

    useEffect(() => {
        // direct replies to argos don't need an inReplyTo section
        inReplyTo && parentArgoId !== inReplyTo._id ? setDisplayInReplyTo(true) : setDisplayInReplyTo(false)
    }, [user])

    useEffect(() => {
        //set the creator placeholder
        if (!creator) {
            setCreatorPlaceholder({
                userName: "[user_deleted]",
                avatar: placeholderAvatar,
            })
        }
    }, [])

    const formattedDate = dayjs(createdAt).format("MMM DD, YYYY")

    const hasVoted = pool => {
        if (!user) return null
        try {
            return rating[pool].indexOf(user._id) === -1 // should return a bool
        } catch (error) {
            errorHandler(error)
        }
    }

    return (
        <li className="argo" ref={node => addRef(_id, node)}>
            <Author {...props} />

            {/* ArgoHeader contains the insight score and mechanics, and the tags (in reply to, new, pro/against) */}
            <ArgoHeader
                _id={_id}
                position={position}
                spendInsight={spendInsight}
                insightScore={insightScore}
                inReplyTo={displayInReplyTo ? inReplyTo : null}
                getRef={getRef} // for efficiency purposes, so the header doesn't call the hook again
            />

            <Content content={content} isEdited={isEdited} />

            {!isLocked && (
                <>
                    {/* Reply button, not available for main Argos */}
                    {position !== "main" && !disableReplies && (
                        <button
                            onClick={() => {
                                setFormType("addReply")
                                setDisplayArgform(d => !d)
                            }}
                            className="inside-argo-button"
                            id="argo-reply-button"
                        >
                            {" "}
                            Reply
                        </button>
                    )}

                    <RatingChip _id={_id} hasVoted={hasVoted} rateArgo={rateArgo} rating={rating} />

                    {/* The positive rating button for an argument & the number*/}
                    {/* This is the three-dots menu that displays owner options */}

                    {!hasBeenDeleted && (
                        <FontAwesomeIcon
                            icon={faEllipsis}
                            className={"owner-options-display-button"}
                            onClick={() => setDisplayOwnerOptions(d => !d)}
                        />
                    )}

                    {/* The owner options */}
                    {/* {
                                position !== "main" && <>
                                <ImBin className={ownerOptions && displayOwnerOptions ? "owner-button" : "disabled" } onClick={
                                    () => {
                                        deleteArgo(_id)
                                        setDisplayOwnerOptions(d => !d)
                                    }}
                                />

                                <FiEdit className={ownerOptions && displayOwnerOptions ? "owner-button" : "disabled" } onClick={() => {
                                        setFormType("editArgo")
                                        setDisplayArgform(d => !d)
                                    }
                                }/>
                                </>
                            } */}

                    {/* <BsFlag className={displayOwnerOptions ? "owner-button" : "disabled"} onClick={() => flagArgo(_id)}/> */}
                    {/* View replies button TODO*/}
                    {/* The replies section [draggable is set to false because it messses the UI]*/}
                </>
            )}
            <Replies {...props} />
        </li>
    )
}

export default Argo
