import { customFetch } from "../hooks/fetch"
import { useUserContext } from "../hooks/useUserContext"
import { useErrorHandler } from "../hooks/useErrorHandler"

export const usePatchRequest = () => {
    const { user, dispatch } = useUserContext()
    const errorHandler = useErrorHandler()
    
    return {
        argos: async function (patchObject) {
            const {path, payload, actions: {setArgos}} = patchObject
            
            try {
                const bearerToken = `Bearer ${user.token}`
                
                const { data, err } = await customFetch(path, {
                    method: "PATCH", 
                    token: bearerToken,
                    payload
                })
                
                if (err) throw err

                const { newArgo, userUpdate } = data
                // setArgos is null, therefore the function will just return the data
                // this is useful for promoting arguments, where the logic is present in the component function, not here. 
                if (!setArgos) return data
                
                // this logic is used by the app to cover argos and main argos basic crud functions 
                if (newArgo.position === 'reply') {
                    setArgos(currentArgos => {
                        // data.parentArgo returns the ID of the parent Argo 
                        const parentArgo = currentArgos.filter(argo => argo._id === newArgo.parentArgo)[0]
        
                        // spread the array like so, so that the comment appears at the beginning  
                        parentArgo.replies = parentArgo.replies.map(reply => reply._id !== newArgo._id ? reply : newArgo)
        
                        // return the new state, replacing the old argo with the new one 
                        return currentArgos.map(argo => argo._id !== parentArgo._id ? argo : parentArgo)
                    })
                } else {
                    // using map here because filter changes the order of the argos and it results in messy rerenders. 
                    setArgos(currentArgos => currentArgos.map(argo => argo._id !== newArgo._id ? argo : newArgo))
                }

                if (userUpdate) {
                    dispatch({type: "UPDATE_USER", payload: userUpdate})
                }

                return data

            } catch (error) {
                errorHandler(error)
            }
        },
        
        debates: async function (patchObject) {
            const {path, payload, actions: {setDebates}} = patchObject
            
            try {
                const bearerToken = `Bearer ${user.token}`
                
                const { data, err } = await customFetch(path, {
                    method: "PATCH", 
                    token: bearerToken,
                    payload
                })
                if (err) throw err
                const {newDebate, userUpdate} = data

                if (userUpdate) {
                    dispatch({type: "UPDATE_USER", payload: userUpdate})
                }

                setDebates(debates => debates.map(debate => debate._id !== newDebate._id ? debate : newDebate))
            } catch (error) {
                errorHandler(error)
            }
        },

        users: async function (patchObject) {
            const {path, payload} = patchObject
            
            try {
                const bearerToken = `Bearer ${user.token}`
                
                const { data, err } = await customFetch(path, {
                    method: "PATCH", 
                    token: bearerToken,
                    payload
                })
                const { userUpdate } = data
                if (err) throw err

                if (userUpdate) {
                    dispatch({type: "UPDATE_USER", payload: userUpdate})
                }
            } catch (error) {
                errorHandler(error)
            }
        }
    }
}