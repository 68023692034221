import React from 'react'
import './ArgoHeader-styles.css'

const ArgoHeader = (props) => {
    const { spendInsight, _id, insightScore, inReplyTo, getRef } = props

    const scrollToArgo = () => {
        if (inReplyTo) {
            const node = getRef(inReplyTo._id)
            node.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center'
            })
        }
    }

    return (
        <header>
            <section className="argo-tags-section">
                {
                    inReplyTo && inReplyTo.creator && <>
                        <span className="inreplyto-tag" onClick={() => scrollToArgo()}>
                            <span className="inreplyto-creator">@{inReplyTo.creator.userName}:</span>
                            <span className="inreplyto-content">{inReplyTo.content}</span>
                        </span>
                    </>
                }
            </section>
            <section className="insight-section" onClick={() => spendInsight(50, _id)}>
                <p> Insight </p> 
                {/* <FaRegLightbulb /> */}
                <span className="insight-number"> {insightScore} </span>
            </section>
        </header>
    )
}

export default ArgoHeader