// intended for usage in tutorial portal
export const useScrollLock = () => {
    const body = document.getElementsByTagName("body")[0]

    return () => {
        body.style.overflow === "" || body.style.overflow === "visible" // that was the issue, body.style.overflow is "" in react 
        ? body.style.overflow = "hidden"
        : body.style.overflow = "visible"
    }
    
}