import { useUserContext } from "./useUserContext"
import { useNavigate } from "react-router-dom"

export const useLogout = () => {
    const { dispatch } = useUserContext()

    return () => {
        localStorage.removeItem('user')
        dispatch({type: "LOGOUT"})
    }
}