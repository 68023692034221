import { useEffect, useState } from "react"
import defaultAvatar from "../../pictures/default-pp.jpg"
import OptionsButton from "./OptionsButton"
import Owner from "./Owner"
import styled from "styled-components"
import OptionsPanel from "./OptionsPanel"
import { useUserContext } from "../../hooks/useUserContext"

const View = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    /* menu ellispis */
    .open-menu-svg {
        cursor: pointer;
        align-self: flex-start;
        border-radius: 0.25rem;
        padding: 0 0.5rem;
        transition: 300ms linear;
        &:hover {
            background-color: var(--blue-grey-2);
        }
    }
    .close-menu-svg {
        cursor: pointer;
        align-self: flex-start;
        border-radius: 0.25rem;
        transition: 300ms linear;
    }
`

const Header = props => {
    const { creator, formattedDate } = props
    const { user } = useUserContext()
    const [isOwner, setIsOwner] = useState(false)
    const [displayOptions, setDisplayOptions] = useState(false)

    useEffect(() => {
        if (user._id === creator._id) {
            setIsOwner(true)
        }
    }, [user, creator])

    return (
        <View>
            <Owner creator={creator} formattedDate={formattedDate} defaultAvatar={defaultAvatar} />
            <OptionsButton displayOptions={displayOptions} setDisplayOptions={setDisplayOptions} />
            <OptionsPanel display={displayOptions} setDisplay={setDisplayOptions} isOwner={isOwner} />
        </View>
    )
}

export default Header
