import React from "react"
import "./Footer-styles.css"
import { Link } from "react-router-dom"

const Footer = ({ version }) => {
    return (
        <footer>
            <ul>
                <li>
                    {" "}
                    <Link to="/t&c">Terms and Conditions</Link>
                </li>
                <li> Community guidelines </li>
                <li>
                    {" "}
                    <Link to="/about"> About us </Link>{" "}
                </li>
                <li> {version} </li>
                <li> youAgree © 2024. All rights reserved </li>
            </ul>
        </footer>
    )
}

export default Footer
