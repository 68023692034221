import React from "react"
import { useUserContext } from "../../hooks/useUserContext"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsis, faXmark } from "@fortawesome/free-solid-svg-icons"

const OptionsButton = props => {
    const { displayOptions, setDisplayOptions, toggleScroll } = props
    const { user } = useUserContext()
    const { width } = useWindowDimensions()

    if (!user) return null

    return !displayOptions ? (
        <FontAwesomeIcon
            icon={faEllipsis}
            onClick={() => {
                setDisplayOptions(true)

                width < 480 && toggleScroll()
            }}
            className="open-menu-svg"
        />
    ) : (
        <FontAwesomeIcon icon={faXmark} className="close-menu-svg" />
    )
}

export default OptionsButton
