import profilePic from "../../pictures/default-pp.jpg"

const Author = props => {
    const {
        creator,
        creatorPlaceholder,
        formattedDate,
        ownerOptions,
        canPromote,
        isLocked,
        position,
        _id,
        promoteArgo,
        promoteReply,
        width,
    } = props

    return (
        <section className="author-section">
            <img
                className="profile-pic-argos"
                alt="profile-pic"
                src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
            />
            <small data-creator> {creator?.userName || creatorPlaceholder.userName} </small>
            <small data-date> {formattedDate} </small>
            {
                // promote button
                ownerOptions &&
                    // (position === "argument" || position === "reply") &&
                    canPromote &&
                    !isLocked && (
                        <button
                            className="argo-promote-button"
                            onClick={() => (position === "argument" ? promoteArgo(_id) : promoteReply(_id))}
                        >
                            {" "}
                            {width > 360 ? "Promote" : ""}{" "}
                        </button>
                    )
            }
        </section>
    )
}

export default Author
