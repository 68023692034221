import React from "react"
import Argform from "./Argform"
import Argo from "./Argo"

const Replies = props => {
    const {
        isLocked,
        replies,
        _id,
        parentArgoId,
        position,
        formType,
        content,
        addArgo,
        deleteArgo,
        rateArgo,
        editArgo,
        displayArgform,
        setDisplayArgform,
        spendInsight,
        promoteReply,
        flagArgo,
        socket,
    } = props

    return (
        <ul className="reply-section">
            {!isLocked && (
                <Argform
                    /* React identifier */
                    key={`opened-by-${_id}`}
                    /* 
                            Prop is called argoId, because this can be accesssed
                            from mainArgos, argos, and replies 
                        */
                    argoId={_id}
                    parentArgoId={parentArgoId}
                    displayArgform={displayArgform}
                    setDisplayArgform={setDisplayArgform}
                    formType={formType}
                    placeholder={"Do you agree? What do you think?"}
                    /* API functions */
                    addArgo={addArgo}
                    deleteArgo={deleteArgo}
                    rateArgo={rateArgo}
                    editArgo={editArgo}
                    content={content}
                    position={position}
                />
            )}

            {replies &&
                !isLocked &&
                replies.map(reply => (
                    <Argo
                        /* React identifier */
                        key={reply._id}
                        /* Database Model props */
                        _id={reply._id} // refers to self
                        creator={reply.creator}
                        rating={reply.rating}
                        content={reply.content}
                        position="reply"
                        isEdited={reply.isEdited}
                        isLocked={reply.isLocked}
                        hasBeenDeleted={reply.hasBeenDeleted}
                        parentDebateId={reply.parentDebate} // added Id at the end of name
                        parentArgoId={reply.parentArgo} // added Id at the end of name
                        replies={[]} // currently replies cannot have replies
                        createdAt={reply.createdAt}
                        insightScore={reply.insightScore}
                        disableReplies={position === "main"}
                        inReplyTo={reply.inReplyTo}
                        canPromote={reply.canPromote}
                        /* API functions */
                        deleteArgo={deleteArgo}
                        rateArgo={rateArgo}
                        editArgo={editArgo}
                        addArgo={addArgo}
                        spendInsight={spendInsight}
                        promoteReply={promoteReply}
                        flagArgo={flagArgo}
                        /* socket */
                        socket={socket}
                    />
                ))}
        </ul>
    )
}

export default Replies
