import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import ErrorPage from "./pages/Error/ErrorPage"
import "./index.css"
import App from "./App"
import BaseLayout from "./layouts/BaseLayout"
import Feed from "./pages/Feed"
import User from "./pages/User/User"
import Debate from "./components/Debates/Debate"

const router = createBrowserRouter([
    {
        path: "/",
        element: <BaseLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <App />,
                children: [
                    {
                        index: true,
                        element: <Feed />,
                    },
                    {
                        path: "user",
                        element: <User />,
                    },
                    {
                        path: "debate/:debateId",
                        element: <Debate />,
                    },
                ],
            },
        ],
    },
])

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)
