export const customFetch = async (url, requestParams, config={}) => {
    const {method, token, payload} = requestParams

    const { 
        additionalHeader=null, 
        stringifyResponse=false,
        stringifyPayload=true,
        removeContentType=false
    } = config

    // replicating the state behaviour 
    let data = null, err = null 
    
    const headers = new Headers({
        'Content-Type': 'application/json',
        'authorization': token
    })

    if (removeContentType) {
        headers.delete("Content-Type")
    }

    if (additionalHeader !== null) {
        headers.append(Object.keys(additionalHeader)[0], Object.values(additionalHeader)[0]) // WARNING: this assumes only one object will be passed
    }
    
    try {
        // check whether the payload is an object, otherwise you will get a JSON.parse error from the back end (400 bad request)
        if (typeof payload !== 'object' && payload) throw new Error("Payload must be object")

        // instantiating response object 
        const responseObject = {  
            method, 
            headers,
        }

        // if there is a payload, then we add a body to the response object
        if (payload) {
            Object.defineProperty(responseObject, "body", {
                value: stringifyPayload ? JSON.stringify(payload) : payload,
                enumerable: true,
                writable: true
            })
        }
        
        const response = await fetch(url, responseObject)
        
        // error handling -- this needs to be verified, but what the overal logic is this: 
        // the backend already handles the text message for the error handling, 
        // so there's no need to do it here. Therefore -- I'm awaiting the response, 
        // and if the request.ok is false, then I use the message in the response
        // to handle the error. Otherwise there is no way of knowing what error it was. 
        
        data = await response.json()

        if (!response.ok) {
            const error = new Error(data.errorMessage) // Error.prototype.message
            error.errorCode = data.errorCode // this is because I can't pass objects in the Error constructor 
            throw error
        }   
        
        if (stringifyResponse) {
            data = JSON.stringify(data)
        }
        
    } catch (error) {
        err = error 
    }

    return {data, err}

}
