import React from 'react'
import './Feedback-styles.css'

const Feedback = (props) => {

    const { displayVote, displayInterestButton, showVote, vote, hasVoted, hasEntered, rejoinDebate } = props

    return (
        !hasVoted
        ? <>
            <div className="feedback-section">
                <div className={displayInterestButton ? "initial-feedback-section" : "disabled"}> 
                    <button onClick={() => showVote()} id="interest-button"> VOTE </button> 
                </div> 

                <div className={displayVote ? "for-against-section" : "disabled"}>     
                    {/* <button onClick={() => vote("agree")}> <FaThumbsUp /> </button>
                    <button onClick={() => vote("disagree")}> <FaThumbsDown /> </button> */}
                </div>
            </div>
        </>
        : !hasEntered && <div className="feedback-section">
            <button id="rejoin-debate-button" onClick={
                () => {
                    rejoinDebate()
                }
            }> <span>DEBATE</span> </button> 
        </div>
         
    )

}

export default Feedback